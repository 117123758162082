.player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1025px) {
  .player {
    position: relative;
    margin-bottom: 0;
    height: 0;
    max-height: 100vh;
  }
}
