body {
  background-color: #1d0c27 !important;
  color: white !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rounded-md {
  border-radius: 0.5rem;
}

.cover {
  object-fit: cover;
}

@supports (backdrop-filter: blur(200px)) {
  .backdrop-blur {
    backdrop-filter: blur(200px);
  }
}

@supports not (backdrop-filter: blur(200px)) {
  .backdrop-blur {
    background-color: #1d0c27;
  }
}
